import * as React from "react";
import type { HeadFC, PageProps } from "gatsby";
import Navbar from "../components/navbar/navbar";
import Footer from "../components/footer/footer";
import { useEffect, useState } from "react";
import Mailbox from "../components/mailbox/mailbox";

const InboxPage = (props: PageProps) => {
    const [mailbox, setMailbox] = useState<string | undefined>(undefined);
    useEffect(() => {
        const param = new URLSearchParams(props.location.search);
        setMailbox(param.get("mailbox") ?? undefined);
    }, [props.location.search]);
    return (
        <div className={"overflow-x-hidden"}>
            <Navbar />
            <main className={"relative z-0 dark:text-stone-50/75 md:min-h-[50vh] lg:min-h-screen"}>
                {mailbox && <Mailbox mailbox={mailbox} />}
            </main>
            <Footer />
        </div>
    );
};

const isBrowser = typeof window !== "undefined";

export const Head: HeadFC = () => {
    let mailbox = "";
    if (isBrowser) {
        const param = new URLSearchParams(window.location.search);
        mailbox = param.get("mailbox") ?? "";
    }
    return (
        <>
            <title>{mailbox} Mailbox | Maildrop</title>
            <meta name="robots" content="noindex" />
        </>
    );
};

export default InboxPage;
