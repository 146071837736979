import * as React from "react";

export interface MailboxLoadingProps {
    mailbox: string;
}

const MailboxLoading = (props: MailboxLoadingProps) => {
    return (
        <div className={"flex items-center justify-center opacity-40 dark:opacity-70 md:pt-16"}>
            <div className={"flex-0 animate-pulse text-lg font-bold md:text-2xl"}>
                Loading {props.mailbox}
                <span className={"hidden md:inline"}>@maildrop.cc</span>
            </div>
            <svg
                version="1.1"
                id="L4"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                enableBackground="new 0 0 0 0"
                xmlSpace="preserve"
                className={"flex-0 ml-2 h-12 w-12 md:h-16 md:w-16 md:pt-1"}>
                <circle fill="currentColor" stroke="none" cx="6" cy="50" r="6">
                    <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.1" />
                </circle>
                <circle fill="currentColor" stroke="none" cx="26" cy="50" r="6">
                    <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.2" />
                </circle>
                <circle fill="currentColor" stroke="none" cx="46" cy="50" r="6">
                    <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.3" />
                </circle>
            </svg>
        </div>
    );
};

export default MailboxLoading;
