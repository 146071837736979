import React, { useEffect, useState } from "react";

interface MailboxAliasProps {
    altinbox: string | undefined;
}

const MailboxAlias = (props: MailboxAliasProps) => {
    const [copied, setCopied] = useState(false);
    useEffect(() => {
        if (!copied) {
            return;
        }
        const timeout = setTimeout(() => setCopied(false), 2000);
        return () => clearTimeout(timeout);
    }, [copied]);
    const copyClick = (evt: React.MouseEvent<HTMLDivElement>) => {
        evt.preventDefault();
        void navigator.clipboard.writeText(`${props.altinbox ?? ""}@maildrop.cc`);
        setCopied(true);
    };
    if (props.altinbox === undefined) {
        return <div className={"my-5 h-4 w-48 animate-pulse rounded-full bg-stone-400 text-sm opacity-20"}>&nbsp;</div>;
    } else {
        return (
            <div
                onClick={copyClick}
                className={"group inline-flex items-start justify-center hover:cursor-pointer"}
                title={"Click to copy to clipboard"}>
                <div className={"mt-4 inline-block h-6 overflow-hidden"}>
                    <div
                        data-cypress={"suggestion"}
                        className={`whitespace-nowrap text-rose-600 underline-offset-2 transition-transform group-hover:text-rose-500 group-hover:underline ${
                            copied ? "-translate-y-10" : "-translate-y-0"
                        }`}>
                        {props.altinbox}@maildrop.cc
                    </div>
                    <div className={`pt-4 transition-transform ${copied ? "-translate-y-10" : "-translate-y-0"}`}>
                        Copied!
                    </div>
                </div>
                <div className={"inline-block p-4 group-hover:text-rose-500"}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="h-6 w-6">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75"
                        />
                    </svg>
                </div>
            </div>
        );
    }
};

export default MailboxAlias;
