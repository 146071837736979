import * as React from "react";
import { GET_INBOX, GET_MESSAGE, DELETE_MESSAGE, Message } from "../../gql";
import { useEffect, useRef, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import Modal from "../modal/modal";

interface QueryVariables {
    mailbox: string;
    id: string;
}

interface MutationReturn {
    delete: boolean;
}

interface QueryReturn {
    message: Message;
}

interface MailboxFullProps {
    inbox: Message[];
    mailbox: string;
    refresh: () => void;
}

const MailboxFull = (props: MailboxFullProps) => {
    const sortedArray = [...props.inbox].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    const [selected, setSelected] = useState("");
    const [message, setMessage] = useState<Message | undefined>(undefined);
    const [slide, setSlide] = useState(false);
    const [viewSource, setViewSource] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [browserWidth] = useState(window.innerWidth);
    const iframeEl = useRef<HTMLIFrameElement>(null);
    const subheadEl = useRef<HTMLDivElement>(null);
    const [loadMessage, { loading, error, data }] = useLazyQuery<QueryReturn, QueryVariables>(GET_MESSAGE, {
        variables: { mailbox: props.mailbox, id: selected },
        notifyOnNetworkStatusChange: true,
        onCompleted: (queryData) => {
            setMessage(queryData.message);
        }
    });
    const [deleteMessage] = useMutation<MutationReturn, QueryVariables>(DELETE_MESSAGE, {
        refetchQueries: [{ query: GET_INBOX, variables: { mailbox: props.mailbox } }],
        variables: { mailbox: props.mailbox, id: selected }
    });
    useEffect(() => {
        if (browserWidth > 768) {
            setSelected(sortedArray[0].id);
        }
        setViewSource(false);
    }, []);
    const resizeIframe = () => {
        if (iframeEl.current) {
            console.log("Resizing iframe");
            const iframe = iframeEl.current;
            resizeFrame(iframe);
        }
    };
    const deleteCurrent = () => {
        setMessage(undefined);
        backButton();
        void deleteMessage({ variables: { mailbox: props.mailbox, id: selected } });
        setTimeout(() => {
            setShowModal(false);
        }, 300);
    };
    const backButton = () => {
        setSelected("");
        setSlide(false);
    };
    const resizeFrame = (frame: HTMLIFrameElement) => {
        frame.height = `${(frame.contentWindow?.document.body.scrollHeight ?? 100) + 20}px`;
    };
    const toggleSource = () => {
        if (iframeEl.current && !viewSource) {
            setViewSource(true);
            const iframe = iframeEl.current;
            // iframe.srcdoc = ``;
            if (iframe.contentWindow) {
                iframe.contentWindow.document.open();
                iframe.contentWindow.document.write(`<pre id="code"></pre>`);
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                iframe.contentWindow.document.getElementById("code")!.textContent = data!.message.data;
                iframe.contentWindow.document.close();
            }
            resizeFrame(iframe);
            iframe.height = `${(iframe.contentWindow?.document.body.scrollHeight ?? 100) + 20}px`;
        } else if (iframeEl.current && viewSource) {
            setViewSource(false);
            const iframe = iframeEl.current;
            iframe.srcdoc = data?.message.html ?? "";
            resizeFrame(iframe);
        }
    };
    useEffect(() => {
        if (selected.length > 0) {
            void loadMessage({ variables: { mailbox: props.mailbox, id: selected } });
            if (!slide) {
                setSlide(true);
                if (window.innerWidth < 768) {
                    window.scrollTo({ top: 0 });
                }
            }
            setViewSource(false);
        }
    }, [selected]);
    return (
        <div className={"flex w-full flex-nowrap items-stretch"}>
            <div
                className={`${
                    slide ? "-translate-x-[100vw] md:translate-x-0" : "translate-x-0"
                } flex-0 mb-16 w-full transition-transform duration-300 md:w-1/3 md:basis-1/3`}>
                <div className={"flex items-center justify-between px-8 pb-2 md:px-0"}>
                    <div className={"subhead pb-0"} ref={subheadEl}>
                        {props.inbox.length} message{props.inbox.length > 1 ? "s" : ""}
                    </div>
                    <button
                        type={"button"}
                        onClick={props.refresh}
                        className={
                            "group inline-flex items-center hover:cursor-pointer hover:bg-gradient-to-r hover:from-pink-500 hover:to-rose-500 hover:bg-clip-text hover:text-transparent"
                        }>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="mr-2 h-5 w-5 group-hover:text-pink-500">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                            />
                        </svg>
                        <span>Refresh</span>
                    </button>
                </div>
                <div>
                    {sortedArray.map((msg: Message) => (
                        <div
                            key={msg.id}
                            className={`message relative p-2 px-8 transition-transform odd:bg-white even:bg-stone-100 hover:cursor-pointer dark:odd:bg-stone-900 dark:even:bg-stone-800 md:px-4 md:first:rounded-t-lg md:last:rounded-b-lg ${
                                selected === msg.id ? "selected" : ""
                            }`}
                            onClick={() => setSelected(msg.id)}>
                            <div className={"truncate font-bold"}>{msg.headerfrom}</div>
                            <div className={"text-sm opacity-50"}>{new Date(msg.date).toLocaleString()}</div>
                            <div className={"truncate"}>{msg.subject}</div>
                        </div>
                    ))}
                </div>
            </div>
            <div
                className={`${
                    slide ? "-translate-x-[calc(100vw+4rem)] md:translate-x-0" : "translate-x-0"
                } flex-0 ml-16 min-w-[100vw] transition-transform duration-300 md:ml-4 md:w-2/3 md:min-w-0 md:flex-1 md:basis-2/3 lg:ml-16`}>
                <div className={"pb-2"}>
                    {loading && <div>&nbsp;</div>}
                    {!loading && !message && <div>&nbsp;</div>}
                    {!loading && message && (
                        <div className={"flex items-center justify-between px-0"}>
                            <div className={"flex-0"} onClick={backButton}>
                                <button
                                    type={"button"}
                                    className={
                                        "group flex items-center pl-4 pr-4 hover:bg-gradient-to-r hover:from-pink-500 hover:to-rose-500 hover:bg-clip-text hover:text-transparent md:hidden"
                                    }>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        className="h-5 w-5 group-hover:text-pink-500">
                                        <path
                                            fillRule="evenodd"
                                            d="M15 10a.75.75 0 01-.75.75H7.612l2.158 1.96a.75.75 0 11-1.04 1.08l-3.5-3.25a.75.75 0 010-1.08l3.5-3.25a.75.75 0 111.04 1.08L7.612 9.25h6.638A.75.75 0 0115 10z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                    <span>Back</span>
                                </button>
                            </div>
                            <div className={"flex-0 inline-flex items-center"}>
                                <button
                                    type={"button"}
                                    className={
                                        "group mr-12 inline-flex items-center hover:cursor-pointer hover:bg-gradient-to-r hover:from-pink-500 hover:to-rose-500 hover:bg-clip-text hover:text-transparent"
                                    }
                                    onClick={toggleSource}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="mr-2 h-5 w-5 group-hover:text-pink-500">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M14.25 9.75L16.5 12l-2.25 2.25m-4.5 0L7.5 12l2.25-2.25M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z"
                                        />
                                    </svg>
                                    <span>View {viewSource ? "Message" : "Source"}</span>
                                </button>
                                <button
                                    type={"button"}
                                    onClick={() => {
                                        setShowModal(true);
                                    }}
                                    className={
                                        "group inline-flex items-center pr-4 hover:cursor-pointer hover:bg-gradient-to-r hover:from-pink-500 hover:to-rose-500 hover:bg-clip-text hover:text-transparent"
                                    }>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="mr-2 h-5 w-5 group-hover:text-pink-500">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                        />
                                    </svg>
                                    <span>Delete</span>
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                {loading && (
                    <div className={"bg-white p-4 px-8 text-stone-900 dark:bg-stone-200 md:rounded-t-lg"}>
                        <div className={"mt-1.5 h-3 w-48 animate-pulse rounded-full bg-stone-300 dark:bg-stone-400"} />
                        <div className={"mt-2 h-3 w-40 animate-pulse rounded-full bg-stone-100 dark:bg-stone-300"} />
                        <div className={"mt-2 h-3 w-80 animate-pulse rounded-full bg-stone-200 dark:bg-stone-300"} />
                    </div>
                )}
                {!loading && message && (
                    <div className={"bg-white p-4 px-8 text-stone-900 dark:bg-stone-200 md:rounded-t-lg"}>
                        <div className={"truncate font-bold"}>{message.headerfrom}</div>
                        <div className={"text-sm opacity-50"}>{new Date(message.date).toLocaleString()}</div>
                        <div className={""}>{message.subject}</div>
                    </div>
                )}
                {!loading && error && (
                    <div className={"text-rose-500"}>An error occurred loading your message, please try again.</div>
                )}
                {loading && (
                    <div className={"mb-16 w-full bg-white p-8 dark:bg-stone-200 md:rounded-b-lg"}>
                        <div className={"h-32 w-full animate-pulse rounded-xl bg-stone-100 dark:bg-stone-300"} />
                    </div>
                )}
                {!loading && message && (
                    <div className={"mb-16 w-full bg-white p-8 dark:bg-stone-200 md:rounded-b-lg"}>
                        <iframe
                            ref={iframeEl}
                            width={"100%"}
                            height={"100%"}
                            onLoad={resizeIframe}
                            className="w-full overflow-scroll"
                            srcDoc={message.html ?? message.data}
                        />
                    </div>
                )}
            </div>
            {showModal && (
                <Modal
                    body={`Are you sure you want to delete "${
                        message?.subject ?? ""
                    }"? This message will be deleted forever.`}
                    onCancel={() => {
                        setTimeout(() => {
                            setShowModal(false);
                        }, 300);
                    }}
                    title={"Delete Message"}
                    confirmLabel={"Yes, Delete"}
                    cancelLabel={"Cancel"}
                    onConfirm={deleteCurrent}
                />
            )}
        </div>
    );
};

export default MailboxFull;
