import * as React from "react";
import { useEffect, useState } from "react";

export interface ModalProps {
    title: string;
    body: string;
    cancelLabel?: string;
    confirmLabel?: string;
    onCancel: () => void;
    onConfirm: () => void;
}

const Modal = (props: ModalProps) => {
    const [show, setShow] = useState(false);
    useEffect(() => {
        setShow(true);
    }, []);
    const cancel = () => {
        setShow(false);
        props.onCancel();
    };
    const confirm = () => {
        setShow(false);
        props.onConfirm();
    };
    return (
        <>
            <div
                className={`absolute left-0 top-0 z-100 h-full w-full bg-stone-50 transition-opacity duration-300 ease-in-out dark:bg-stone-900 ${
                    show ? "opacity-100" : "opacity-0"
                }`}
                onClick={cancel}></div>
            <div
                className={`absolute left-0 top-16 z-120 flex justify-center transition-all duration-300 ease-in-out md:left-[calc((100%-50ch)/2)] ${
                    show ? "translate-y-0 scale-100 opacity-100" : "translate-y-12 scale-90 opacity-0"
                }`}>
                <div className={"flex-0 w-full bg-white dark:bg-stone-800 md:w-[50ch] md:rounded-lg md:shadow-md"}>
                    <div className={"flex flex-col items-center p-8 md:flex-row md:items-start"}>
                        <div className={"flex-0 mb-4 opacity-50 dark:text-stone-50 dark:opacity-80 md:mr-4 md:mb-0"}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="h-8 w-8">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                                />
                            </svg>
                        </div>
                        <div className={"flex-1"}>
                            <div className={"text-center text-lg dark:text-stone-50 md:text-left"}>{props.title}</div>
                            <div className={"pt-4 opacity-60 dark:text-stone-50"}>{props.body}</div>
                        </div>
                    </div>
                    <div
                        className={
                            "bg-stone-100 px-8 pt-8 pb-8 text-right dark:bg-stone-700 md:rounded-b-lg md:pb-4 md:pt-4"
                        }>
                        <button
                            onClick={cancel}
                            className={
                                "mb-8 w-full rounded-md border-2 border-stone-300 py-2 px-4 hover:border-rose-500 hover:text-rose-500 md:mb-0 md:mr-8 md:w-auto"
                            }>
                            {props.cancelLabel ?? "Cancel"}
                        </button>
                        <button
                            onClick={confirm}
                            className={
                                "w-full rounded-md border-2 border-rose-500 bg-rose-500 py-2 px-4 text-stone-50 hover:border-rose-400 hover:bg-gradient-to-r hover:from-pink-400 hover:to-rose-400 md:w-auto"
                            }>
                            {props.confirmLabel ?? "Confirm"}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Modal;
