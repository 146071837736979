import * as React from "react";
import empty from "../../images/empty.png";

interface MailboxEmptyProps {
    mailbox: string;
    refresh: () => void;
}

const MailboxEmpty = (props: MailboxEmptyProps) => {
    return (
        <div className={"md:flex-0 px-8 md:flex md:basis-full md:items-start md:justify-center md:px-0"}>
            <div className={"md:flex-1 md:basis-1/2 md:justify-center md:pt-8 lg:pt-16"}>
                <div className={"pb-8 text-center text-xl"}>
                    The mailbox {props.mailbox}@maildrop.cc is currently empty.
                </div>
                <div className={"flex flex-col items-center justify-center pb-8 text-center lg:flex-row"}>
                    <button
                        type={"button"}
                        onClick={props.refresh}
                        className={
                            "order-2 mt-4 inline-flex w-full flex-none transform cursor-pointer items-center justify-center rounded-md border-0 bg-rose-500 p-2 px-4 text-stone-50 transition duration-300 ease-in-out hover:bg-gradient-to-r hover:from-pink-400 hover:to-rose-400 focus-visible:bg-gradient-to-r focus-visible:from-pink-400 focus-visible:to-rose-400 focus-visible:outline-rose-600 active:from-pink-500 active:to-rose-500 dark:bg-rose-700 dark:hover:from-pink-600 dark:hover:to-rose-600 dark:focus-visible:from-pink-600 dark:focus-visible:to-rose-600 dark:active:from-pink-700 dark:active:to-rose-700 md:mt-0 md:w-auto lg:order-1"
                        }>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="h-3.5 w-3.5">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                            />
                        </svg>

                        <span className={"ml-2"}>Refresh Mailbox</span>
                    </button>
                    <a
                        href={`mailto:${props.mailbox}@maildrop.cc`}
                        className={
                            "linkarrow order-1 mb-4 inline-flex items-center py-3 text-rose-600 lg:order-2 lg:ml-8 lg:mb-0"
                        }>
                        <div className={"flex-none"}>Email {props.mailbox}@maildrop.cc</div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            className="h-5 w-5">
                            <path
                                fillRule="evenodd"
                                d="M5 10a.75.75 0 01.75-.75h6.638L10.23 7.29a.75.75 0 111.04-1.08l3.5 3.25a.75.75 0 010 1.08l-3.5 3.25a.75.75 0 11-1.04-1.08l2.158-1.96H5.75A.75.75 0 015 10z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </a>
                </div>
            </div>
            <div className={"flex justify-center md:flex-1 md:basis-1/2"}>
                <img src={empty} alt={"Empty mailbox"} className={"mt-16 h-[300px] md:mt-0 lg:h-[500px]"} />
            </div>
        </div>
    );
};

export default MailboxEmpty;
