import * as React from "react";
import { useQuery } from "@apollo/client";
import MailboxLoading from "./mailboxloading";
import MailboxEmpty from "./mailboxempty";
import MailboxAlias from "./mailboxalias";
import { Link } from "gatsby";
import MailboxFull from "./mailboxfull";
import { GET_INBOX, Message } from "../../gql";

interface QueryVariables {
    mailbox: string;
}

interface QueryReturn {
    inbox: Message[];
    altinbox: string;
}

interface InboxProps {
    mailbox: string;
}

const Mailbox = (props: InboxProps) => {
    const { loading, error, data, refetch } = useQuery<QueryReturn, QueryVariables>(GET_INBOX, {
        variables: { mailbox: props.mailbox },
        notifyOnNetworkStatusChange: true
    });
    return (
        <div>
            <div
                className={
                    "pb-16 pt-16 md:container md:mx-auto md:min-h-[50vh] md:px-8 md:pb-0 lg:min-h-[calc(100vh-12rem)]"
                }>
                <div className={"items-start justify-center md:flex"}>
                    {loading && <MailboxLoading mailbox={props.mailbox} />}
                    {!loading && data?.inbox && data.inbox.length === 0 && (
                        <MailboxEmpty mailbox={props.mailbox} refresh={() => void refetch()} />
                    )}
                    {error && <p>Error: {JSON.stringify(error)}</p>}
                    {!loading && data?.inbox && data.inbox.length > 0 && (
                        <MailboxFull inbox={data.inbox} mailbox={props.mailbox} refresh={() => void refetch()} />
                    )}
                </div>
            </div>
            <div className={"bg-stone-200 text-stone-50 dark:bg-stone-800 dark:text-stone-900"}>
                <svg
                    viewBox="0 30 960 100"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    className={"w-full"}
                    version="1.1">
                    <path
                        d="M0 54L40 49.7C80 45.3 160 36.7 240 36.5C320 36.3 400 44.7 480 50.2C560 55.7 640 58.3 720 56.2C800 54 880 47 920 43.5L960 40L960 0L920 0C880 0 800 0 720 0C640 0 560 0 480 0C400 0 320 0 240 0C160 0 80 0 40 0L0 0Z"
                        fill="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="miter"></path>
                </svg>
            </div>
            <section
                className={"bg-gradient-to-b from-stone-200 to-stone-50 pb-32 dark:from-stone-800 dark:to-stone-900"}>
                <div className={"px-8 md:container md:mx-auto"}>
                    <div className={"items-start md:flex"}>
                        <div className={"md:flex-0 md:mr-4 md:basis-1/2"}>
                            <h2 className={"pt-8 pb-4 text-3xl font-bold md:pt-0"}>Mailbox Alias</h2>
                            <MailboxAlias altinbox={data?.altinbox} />
                            <div className={"pt-0.5 lg:max-w-[50ch]"}>
                                Email sent to the above alias address will also show up in this mailbox. Use an alias
                                address when you need a little bit more security - people cannot view the alias address
                                mailbox without knowing the original address.
                            </div>
                        </div>
                        <div className={"md:flex-0 md:ml-4 md:basis-1/2"}>
                            <div className={"pt-16 md:pt-0"}>
                                <h2
                                    className={
                                        "inline-block bg-gradient-to-r from-pink-500 to-rose-500 bg-clip-text pb-4 text-3xl font-bold text-transparent"
                                    }>
                                    Maildrop Sponsors
                                </h2>
                                <div className={"pt-1"}>
                                    <a
                                        href={`https://heluna.com/`}
                                        className={
                                            "linkarrow order-1 mb-4 mb-0 inline-flex items-center py-3 font-bold lg:order-2"
                                        }>
                                        <div className={"flex-none"}>Heluna Antispam</div>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            className="h-5 w-5">
                                            <path
                                                fillRule="evenodd"
                                                d="M5 10a.75.75 0 01.75-.75h6.638L10.23 7.29a.75.75 0 111.04-1.08l3.5 3.25a.75.75 0 010 1.08l-3.5 3.25a.75.75 0 11-1.04-1.08l2.158-1.96H5.75A.75.75 0 015 10z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </a>
                                </div>
                                <div className={"max-w-[50ch] pt-1"}>
                                    Comprehensive cloud-based email protection for your entire domain. Use Heluna to
                                    protect your organization from spam, phishing, and other email attacks.
                                </div>
                                <div className={"max-w-[50ch] pt-8"}>
                                    Maildrop keeps running thanks to the generosity of its sponsors. Please{" "}
                                    <Link
                                        to={"/contact-us"}
                                        className={
                                            "text-rose-600 underline-offset-2 hover:text-rose-500 hover:underline"
                                        }>
                                        contact us
                                    </Link>{" "}
                                    if you're interested in sponsoring Maildrop.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Mailbox;
